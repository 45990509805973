<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('report')" :is-filter="false">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('report')" :is-filter="false">
                </HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="form">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="report" rules="required" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('report')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]" :options="reportOptions"
                                                         v-model="form.report"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-sm-6">
                                <ValidationProvider name="response_type" rules="required" v-slot="{valid, errors}"
                                                    v-if="showReportType">
                                    <b-form-group :label="$t('report_type')">
                                        <multi-selectbox :allow-empty="false" :multiple="false"
                                                         :validate-error="errors[0]" :options="responseTypeOptions"
                                                         v-model="form.response_type"></multi-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showPeriod">
                                        <ValidationProvider name="period_id" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('period')">
                                                <payment-periods-selectbox
                                                    :multiple="true"
                                                    :semesters="semesters"
                                                    :validate-error="errors[0]"
                                                    v-model="form.period_id">
                                                </payment-periods-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showCurrency">
                                        <ValidationProvider name="currency" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('currency')">
                                                <multi-selectbox
                                                    :multiple="isCurrecyMultiple"
                                                    :options="computedCurrencies"
                                                    :validate-error="errors[0]"
                                                    v-model="form.currency">
                                                </multi-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDates">
                                        <ValidationProvider name="start_date" rules="required">
                                            <b-form-group :label="$t('start_date')">
                                                <b-input type="date"
                                                         v-model="form.start_date">
                                                </b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showDates">
                                        <ValidationProvider name="end_date" rules="required" >
                                            <b-form-group :label="$t('end_date')">
                                                <b-input type="date"
                                                         v-model="form.end_date">
                                                </b-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-if="showSemester">
                                        <ValidationProvider name="semester_id" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('registration_semester')">
                                                <semesters-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="form.semester_id">
                                                </semesters-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>

                            <div class="col-6 col-xs-12" v-if="showNumbers">
                                <ValidationProvider name="number" rules="" v-slot="{valid, errors}">
                                    <b-form-group :label="$t('student_numbers')">
                                        <lined-textarea v-model="form.number"
                                                        :nowrap="false"
                                                        :disabled="false"
                                                        :styles="{ height: '15em', resize: 'both' }"
                                                        :validateError="errors[0]">
                                        </lined-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <processing-button @click="send" label="get_report"
                                                   :processing="processing"></processing-button>
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout"
import Header from '@/layouts/AppLayout/Header'
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile'
import {ValidationObserver, ValidationProvider} from "vee-validate";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import FinanceReportService from "@/services/FinanceReportService";
import ProcessingButton from "@/components/elements/ProcessingButton";
import LinedTextarea from "../../../components/elements/LinedTextarea";
import SemestersSelectbox from "../../../components/interactive-fields/SemestersSelectbox";

export default {
    components: {
        SemestersSelectbox,
        LinedTextarea,
        ProcessingButton,
        PaymentPeriodsSelectbox,
        MultiSelectbox,
        AppLayout,
        ValidationProvider,
        ValidationObserver,
        Header,
        HeaderMobile
    },
    metaInfo() {
        return {
            title: this.$t('report')
        }
    },
    data() {
        return {
            showPeriod: false,
            showCurrency: false,
            isCurrecyMultiple: false,
            showDates: false,
            showSemester: false,
            showNumbers: false,
            showReportType: true,
            form: {
                response_type: 'page'
            },
            reportOptions: [
                {value: '1', text: this.$t('number_of_students_report')},
                {value: '2', text: this.$t('collection_report')},
                {value: '3', text: this.$t('cash_collection_report')},
                {value: '4', text: this.$t('pilotage_collection_report')},
                {value: '5', text: this.$t('student_collection_report')},
                {value: '6', text: this.$t('education_credits_report')},
                {value: '7', text: this.$t('early_payment_report')},
                {value: '8', text: this.$t('graduate_full_scholarship_ratio_report')},
            ],
            responseTypeOptions: [
                {value: 'page', text: this.$t('page')},
                {value: 'excel', text: this.$t('excel')},
                {value: 'pdf', text: 'PDF'},
            ],
            processing: false,
            reportName: "",
            semesters: null,
            currencies: [
                {value: null,  text: this.$t('select')},
                {value: 'USD', text:'USD'},
                {value: 'EUR', text:'EUR'},
            ],
            showAllTL:false
        }
    },
    methods: {
        async send() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                this.processing = true;
                this.reportName = this.getOptionText(this.reportOptions, this.form.report);
                let config = {params: this.form};
                let isArrayBuffer=false;
                if (this.form.response_type != 'page') {
                    config.responseType = 'arraybuffer';
                    isArrayBuffer=false;
                }
                let oldNumber=this.form.number;
                if(oldNumber){
                    config.params.number=config.params.number.split(/\n/);
                }

                if (this.form.report == '1' && this.checkPermission('report_numberofstudents')) {
                    FinanceReportService.numberOfStudents(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '2' && this.checkPermission('report_collection')) {
                    FinanceReportService.collection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '3' && this.checkPermission('report_cashcollection')) {
                    FinanceReportService.cashCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                } else if (this.form.report == '4' && this.checkPermission('paymentplan_pilotagereport')) {
                    FinanceReportService.pilotage(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '5' && this.checkPermission('report_studentcollection')) {
                    FinanceReportService.studentCollection(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '6' && this.checkPermission('report_educationcredits')) {
                    FinanceReportService.educationCredits(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '7' && this.checkPermission('report_earlypayment')) {
                    FinanceReportService.earlyPayment(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else if (this.form.report == '8') {
                    FinanceReportService.graduateFullScholarshipRatio(config).then(this.callback).catch(e => {
                        this.showErrors(e, this.$refs.form, isArrayBuffer);
                    }).finally(() => {
                        this.processing = false;
                    });
                }
                else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }
                this.form.number=oldNumber;
            }
        },
        callback(response) {
            if (this.form.response_type == 'page') {
                this._openFile(response);
            }
            else if (this.form.response_type == 'excel') {
                this._downloadFile(response, this.reportName + '.xlsx');
            }
            else if (this.form.response_type == 'pdf') {
                this._downloadFile(response, this.reportName + '.pdf');
            }
        }
    },
    watch: {
        'form.report'(newVal, oldVal) {

            if (newVal != oldVal) {
                this.form = {response_type: 'page', report: newVal}
            }

            this.showDates = false;
            this.showPeriod = false;
            this.showCurrency = false;
            this.showReportType = true;
            this.semesters=null;
            this.showAllTL=false;
            this.showNumbers=false;
            this.isCurrecyMultiple = false;

            if (newVal == '2') {
                this.showPeriod = true;
                this.semesters = [0,1,2,3,5,6];
                this.showCurrency = true;
            } else if (newVal == '3') {
                this.showDates = true;
                this.showPeriod = true;
                this.showCurrency = true;
                this.showAllTL=true;
            } else if (newVal == '4') {
            }
            else if (newVal == '5') {
                this.showPeriod = true;
                this.semesters = [0,1,2,3,5,6];
                this.showReportType = false;
                this.form.response_type = 'excel';
                this.showCurrency = true;
            }
            else if (newVal == '6') {
                this.showNumbers=true;
            }
            else if (newVal == '7') {
                this.showDates = true;
                this.showPeriod = true;
            }
        }
    },
    computed:{
        computedCurrencies(){
            return this.currencies.filter((item)=>{
                return item.value!='TL' || this.showAllTL;
            })
        }
    }
};
</script>

